export function Smile({className}) {
  return (
    <svg className={className} width="387" height="292" viewBox="0 0 387 292" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ddd)">
      <path d="M131.055 38.1623C129.691 36.3718 128.386 34.5514 127.139 32.7013C126.482 31.7257 125.114 31.4566 124.146 32.0983L92.4033 52.894C91.42 53.531 91.1608 54.8763 91.8221 55.8373C109.519 81.8624 134.093 102.464 162.957 115.532C169.36 118.424 175.932 120.936 182.647 123.044C189.347 125.147 196.19 126.847 203.083 128.129C234.237 133.901 266.171 131.055 295.572 119.799C296.668 119.373 297.22 118.136 296.777 117.051L282.615 81.8449C282.172 80.7602 280.91 80.2039 279.814 80.6291C277.718 81.4299 275.607 82.1777 273.479 82.8723C253.208 89.4284 231.633 90.7823 210.565 86.8777C205.386 85.909 200.249 84.6491 195.213 83.0681C190.176 81.4872 185.236 79.5998 180.437 77.42C160.873 68.5695 143.942 55.1276 131.055 38.1623Z" fill="#3A8AE3"/>
      </g>
      <defs>
      <filter id="filter0_ddd" x="0" y="0" width="386.255" height="291.262" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4.56296"/>
      <feGaussianBlur stdDeviation="3.25926"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.541176 0 0 0 0 0.890196 0 0 0 0.0981481 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="21.5704"/>
      <feGaussianBlur stdDeviation="12.7407"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.541176 0 0 0 0 0.890196 0 0 0 0.151852 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="56"/>
      <feGaussianBlur stdDeviation="40"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.541176 0 0 0 0 0.890196 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
      </filter>
      </defs>
    </svg>
  )
}

export function SmileLarge({className}) {
  return (
    <svg className={className} width="271" height="135" viewBox="0 0 271 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-26.418 47.8911C-29.6852 46.3479 -32.8902 44.7215 -36.0332 43.0119C-37.6891 42.1098 -39.8525 42.7037 -40.7768 44.2916L-71.2464 96.0031C-72.1956 97.595 -71.5866 99.666 -69.9348 100.544C-25.5004 124.482 24.864 135.929 75.9015 133.762C87.2189 133.271 98.5009 132.128 109.694 130.316C120.862 128.508 131.942 126.032 142.788 122.936C191.796 108.909 235.636 82.2385 269.699 45.5712C270.965 44.1961 270.855 42.0687 269.427 40.8616L223.377 1.53555C221.949 0.328466 219.729 0.44401 218.463 1.81906C216.034 4.43052 213.543 6.97883 210.991 9.464C186.627 33.0556 156.591 50.2763 123.45 59.7603C115.296 62.0796 106.991 63.9602 98.5957 65.319C90.2008 66.6778 81.7207 67.5385 73.2296 67.889C38.6421 69.3676 4.49022 62.5363 -26.418 47.8911Z" fill="#3A8AE3"/>
    </svg>
  )
}

export function SmileSecondary({className}) {
  return (
    <svg className={className} width="444" height="359" viewBox="0 0 444 359" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ddd)">
    <path d="M161.766 46.9267C160.09 44.7254 158.485 42.4875 156.952 40.2129C156.145 39.0136 154.463 38.6827 153.272 39.4716L114.248 65.0376C113.039 65.8208 112.721 67.4746 113.534 68.656C135.291 100.651 165.501 125.978 200.986 142.044C208.858 145.6 216.937 148.688 225.193 151.279C233.43 153.865 241.842 155.954 250.316 157.53C288.617 164.627 327.876 161.127 364.021 147.289C365.369 146.766 366.047 145.245 365.503 143.912L348.091 100.629C347.547 99.2959 345.996 98.612 344.648 99.1347C342.072 100.119 339.476 101.039 336.861 101.892C311.94 109.953 285.415 111.617 259.515 106.817C253.147 105.626 246.832 104.077 240.641 102.133C234.449 100.19 228.376 97.8693 222.476 95.1894C198.425 84.3089 177.61 67.7836 161.766 46.9267Z" fill="#1EAFCD"/>
    </g>
      <defs>
        <filter id="filter0_ddd" x="0.64917" y="0.010498" width="474.857" height="358.074" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="5.60964"/>
        <feGaussianBlur stdDeviation="4.00689"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.541176 0 0 0 0 0.890196 0 0 0 0.0981481 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="26.5183"/>
        <feGaussianBlur stdDeviation="15.6633"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.541176 0 0 0 0 0.890196 0 0 0 0.151852 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="68.8456"/>
        <feGaussianBlur stdDeviation="49.1754"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.541176 0 0 0 0 0.890196 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}
