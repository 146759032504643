export function Logo() {
  return (
    <svg width="81" height="36" viewBox="0 0 81 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.22882 8.81791V0.934972C5.22882 0.791988 5.10864 0.673462 4.96366 0.673462H0.265161C0.120181 0.673462 0 0.790107 0 0.934972V35.3546C0 35.4976 0.120181 35.6161 0.265161 35.6161H4.96557C5.11055 35.6161 5.23073 35.4976 5.23073 35.3546V18.5446C5.23073 15.4441 7.80603 12.9042 10.9498 12.9042C14.0936 12.9042 16.6689 15.4441 16.6689 18.5446V35.3527C16.6689 35.4957 16.7872 35.6143 16.9341 35.6143H21.6345C21.7794 35.6143 21.8996 35.4957 21.8996 35.3527V18.5446C21.8996 12.6032 16.976 7.74741 10.9517 7.74741C9.11085 7.74741 7.30242 8.20646 5.69047 9.08318C5.59127 9.13586 5.48063 9.13586 5.38525 9.07942C5.28414 9.02298 5.22882 8.92703 5.22882 8.81791Z" fill="black"/>
      <path d="M66.1872 0.0996911C59.5925 0.903036 54.6384 6.44931 54.6384 13.0002V31.5618C54.6384 31.7048 54.7586 31.8233 54.9036 31.8233H59.604C59.749 31.8233 59.8692 31.7048 59.8692 31.5618V13.0002C59.8692 9.17353 62.6791 5.89243 66.5077 5.26593C66.9407 5.19444 67.3795 5.15869 67.8202 5.15869C68.2608 5.15869 68.6996 5.19444 69.1326 5.26593L69.2356 5.28286C73.0166 5.94887 75.7712 9.21492 75.7712 13.0002C75.7712 16.8269 72.9631 20.1081 69.1326 20.7345C68.6996 20.806 68.2608 20.8418 67.8202 20.8418C67.3814 20.8418 66.9407 20.806 66.5077 20.7345L66.3971 20.7157C65.1686 20.4994 64.0106 19.9952 63.0167 19.2501C62.9328 19.1862 62.8336 19.1768 62.7382 19.2238C62.6428 19.2708 62.5913 19.3536 62.5913 19.4571V24.7626C62.5913 24.8736 62.6505 24.962 62.7554 25.0034C63.8523 25.453 65.0083 25.754 66.1872 25.897C66.729 25.9629 67.2746 25.9967 67.8202 25.9967C68.3657 25.9967 68.9113 25.9629 69.4531 25.897C76.0478 25.0937 81.0019 19.5474 81.0019 12.9965C81.0019 6.44743 76.0478 0.901155 69.4531 0.0959284C68.9113 0.0300805 68.3657 -0.00378418 67.8202 -0.00378418C67.2746 -2.14411e-05 66.729 0.0338432 66.1872 0.0996911Z" fill="black"/>
      <path d="M48.9518 0.635864H44.2514C44.1064 0.635864 43.9862 0.754391 43.9862 0.897375V14.368C43.9862 17.4685 41.4109 20.0083 38.2671 20.0083C35.1233 20.0083 32.548 17.4685 32.548 14.368V0.897375C32.548 0.754391 32.4279 0.635864 32.2829 0.635864H27.5844C27.4394 0.635864 27.3192 0.754391 27.3192 0.897375V14.368C27.3192 20.3093 32.2447 25.1652 38.269 25.1652C44.2933 25.1652 49.2169 20.3093 49.2169 14.368V0.897375C49.2169 0.754391 49.0987 0.635864 48.9518 0.635864Z" fill="black"/>
      <path d="M29.1257 27.9364C28.9006 27.7765 28.6813 27.611 28.4676 27.4398C28.3551 27.3495 28.1853 27.3683 28.0956 27.4793L25.1483 31.0896C25.0568 31.2006 25.0758 31.3681 25.1884 31.4565C28.212 33.859 31.8403 35.3716 35.6937 35.8419C36.5483 35.9454 37.4087 35.9981 38.2709 35.9981C39.1313 35.9981 39.9935 35.9454 40.8462 35.8419C44.6997 35.3716 48.328 33.8609 51.3516 31.4565C51.4641 31.3662 51.4832 31.2006 51.3916 31.0896L48.4443 27.4793C48.3528 27.3683 48.1849 27.3495 48.0724 27.4398C47.8568 27.611 47.6374 27.7765 47.4142 27.9364C45.2853 29.4528 42.813 30.4067 40.2072 30.7246C39.5662 30.8018 38.9195 30.8432 38.2728 30.8432C37.6262 30.8432 36.9795 30.8036 36.3385 30.7246C33.727 30.4067 31.2547 29.4528 29.1257 27.9364Z" fill="#3A8AE3"/>
    </svg>
  )
}

