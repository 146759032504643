import React from 'react'
import { Link } from 'react-router-dom'
import { Arch } from '../../../../assets'
import styles from './style.module.scss'

import tm1 from "../../../../assets/tm1.webp"
import tm2 from "../../../../assets/tm2.webp"
import tm3 from "../../../../assets/tm3.webp"
import tm4 from "../../../../assets/tm4.webp"
import tm5 from "../../../../assets/tm5.webp"
import tm6 from "../../../../assets/tm6.webp"
import tm7 from "../../../../assets/tm7.webp"

const teammates = [
  { name: "Luiz Bouch", img: tm5 },
  { name: "Leonardo Pires", img: tm4 },
  { name: "Marina Zafiro", img: tm3 },
  { name: "Gabriel França", img: tm7 },
  { name: "Rafael França", img: tm2 },
  { name: "Ivan Russi", img: tm6 }
]

export function Team() {
  return (
    <section className={styles.team}>
      <div className="container">
        <div className="flex">
          <div className={styles.column}>
            <div className={styles.content}>
              <p className={styles.category}>QUEM SOMOS</p>
              <h1 className={styles.title}>Nosso time de Expansão</h1>

              <p className={styles.text}>Composto por apaixonados em tecnologia, engenharia e arquitetura, esse time tem experiência em expansões de Incorporadoras, Startups e Varejo.</p>

              <Link to="/quem-faz" className={`button button--white ${styles.button}`}>Conheça mais <span>→</span></Link>
            </div>
          </div>
          <div className={`${styles.column} ${styles['column--large']}`}>
            <div className={styles.teammates}>
              {
                [1,2,3,4].map((e, index) => {
                  return <Arch key={`arch_${index}`} className={`${styles.arch} ${styles[`arch_${index}`]}`} />
                })
              }

              {
                teammates.map((teammate, i) => {
                  return <img
                            key={teammate.name}
                            className={`${styles.teammate} ${styles[`teammate_${i}`]} animation-tm${i}`}
                            src={teammate.img}
                            alt={teammate.name}
                          />
                })
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
