export function Loading({className}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
    style={{margin: "auto", background: "rgb(255, 255, 255)", display: "block", shapeRendering: "auto"}} width="137px" height="137px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <rect x="17" y="46" width="16" height="8" fill="#3a8ae3">
        <animate attributeName="y" repeatCount="indefinite" dur="0.9615384615384615s" calcMode="spline" keyTimes="0;0.5;1" values="42;46;46" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.19230769230769232s"></animate>
        <animate attributeName="height" repeatCount="indefinite" dur="0.9615384615384615s" calcMode="spline" keyTimes="0;0.5;1" values="16;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.19230769230769232s"></animate>
      </rect>
      <rect x="42" y="46" width="16" height="8" fill="#1eafcd">
        <animate attributeName="y" repeatCount="indefinite" dur="0.9615384615384615s" calcMode="spline" keyTimes="0;0.5;1" values="43;46;46" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.09615384615384616s"></animate>
        <animate attributeName="height" repeatCount="indefinite" dur="0.9615384615384615s" calcMode="spline" keyTimes="0;0.5;1" values="14;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.09615384615384616s"></animate>
      </rect>
      <rect x="67" y="46" width="16" height="8" fill="#3a8ae3">
        <animate attributeName="y" repeatCount="indefinite" dur="0.9615384615384615s" calcMode="spline" keyTimes="0;0.5;1" values="43;46;46" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
        <animate attributeName="height" repeatCount="indefinite" dur="0.9615384615384615s" calcMode="spline" keyTimes="0;0.5;1" values="14;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
      </rect>
    </svg>
  )
}
