import React from 'react'
import styles from './style.module.scss'

import map from '../../assets/mapahup.webp'

export function Location() {
  return (
    <section
      className={styles.location}
      style={{backgroundImage: `url(${map})`}}
    >
      <div className="container">
        <div className={styles.box}>
          <p className={styles.title}>Hup XP</p>
          <p className={styles.address}><strong>Endereço</strong>: Rua Butantã, 336 - 10º Andar - 05424-140 - São Paulo/SP </p>

          <p className={styles.phone}><strong>Telefone</strong>: (11) 2391-7842</p>
          <p className={styles.mail}><strong>E-mail</strong>: contato@hupxp.com</p>
        </div>
      </div>
    </section>
  )
}
