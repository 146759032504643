export function ArrowRight() {
  return (
    <svg width="35" height="15" viewBox="0 0 35 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.3431 0.292893L34.7071 6.65685C35.0976 7.04738 35.0976 7.68054 34.7071 8.07107L28.3431 14.435C27.9526 14.8256 27.3195 14.8256 26.9289 14.435C26.5384 14.0445 26.5384 13.4113 26.9289 13.0208L31.5858 8.36396L0 8.36396L0 6.36396L31.5858 6.36396L26.9289 1.70711C26.5384 1.31658 26.5384 0.683418 26.9289 0.292893C27.3195 -0.0976311 27.9526 -0.0976311 28.3431 0.292893Z" fill="white"/>
    </svg>
  )
}


export function ArrowBlueRight({className}) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0L9.885 2.115L18.255 10.5H0V13.5H18.255L9.885 21.885L12 24L24 12L12 0Z" fill="#3A8AE3"/>
    </svg>
  )
}
