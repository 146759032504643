import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowRight } from '../../../../assets'
import Slider from 'react-slick'
import { client } from '../../../../services/api'

import "slick-carousel/slick/slick.css"
import styles from './style.module.scss'

export function Portfolio() {
  const [ portfolio, setPortfolio ] = React.useState([])

  const portfolioMobileSettings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: false,
    infinite: true,
    centerPadding: "10%",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const getPortfolio = async () => {
    const data = await client.getEntries({
      content_type: "portfolio",
      limit: 4
    })

    setPortfolio(data.items)
  }

  React.useEffect(() => {
    getPortfolio()
  }, [])

  return (
    <section className={styles.portfolio}>
      <div className="flex align-start">
        {
          portfolio && portfolio.length !== 0 &&
            <div className={styles.mosaic}>
              <div className={styles.group}>
                <div className="flex align-start">
                  <article
                    className={`${styles.image} ${styles['image--large']}`}
                    style={{backgroundImage: `url(${portfolio[0]?.fields?.images[0]?.fields?.file?.url}`}}
                  >
                    <div className={styles.content}>
                      <p className={styles.category}>Projeto</p>
                      <h1 className={styles.title}>{portfolio[0]?.fields?.title}</h1>

                      <Link to="/portfolio" className={styles.button}>
                        <ArrowRight />
                      </Link>
                    </div>
                  </article>
                  <article
                    className={styles.image}
                    style={{backgroundImage: `url(${portfolio[1]?.fields?.images[0]?.fields?.file?.url}`}}
                  >
                    <div className={styles.content}>
                      <p className={styles.category}>Projeto</p>
                      <h1 className={styles.title}>{portfolio[1]?.fields?.title}</h1>

                      <Link to="/portfolio" className={styles.button}>
                        <ArrowRight />
                      </Link>
                    </div>
                  </article>
                </div>
              </div>
              <div className={styles.group}>
                <div className="flex">
                  <article
                    className={styles.image}
                    style={{backgroundImage: `url(${portfolio[2]?.fields?.images[0]?.fields?.file?.url}`}}
                  >
                    <div className={styles.content}>
                      <p className={styles.category}>Projeto</p>
                      <h1 className={styles.title}>{portfolio[2]?.fields?.title}</h1>

                      <Link to="/portfolio" className={styles.button}>
                        <ArrowRight />
                      </Link>
                    </div>
                  </article>
                  <article
                    className={`${styles.image} ${styles['image--large']}`}
                    style={{backgroundImage: `url(${portfolio[3]?.fields?.images[0]?.fields?.file?.url}`}}
                  >
                    <div className={styles.content}>
                      <p className={styles.category}>Projeto</p>
                      <h1 className={styles.title}>{portfolio[3]?.fields?.title}</h1>

                      <Link to="/portfolio" className={styles.button}>
                        <ArrowRight />
                      </Link>
                    </div>
                  </article>
                </div>
              </div>
            </div>
        }
        <div className={styles['mosaic__holder']}></div>
      </div>

      <div className="container">
        <div className="flex home-portfolio">
          <div className={styles.column}></div>
          <div className={styles.column}>
            <p className={styles.category}>Portfólio</p>
            <h1 className={styles.title}>Nossos principais produtos</h1>

            <p className={styles.text}>Conheça o nosso portfólio que contempla reformas de <strong>FLIPPING HOUSES</strong>, <strong>SMALL Renovations</strong>, <strong>COMPLETE Renovations</strong> e <strong>FULL-SERVICE Renovations</strong>.</p>

            {
              portfolio && portfolio.length !== 0 &&
                <div className={styles.portfolioMobile}>
                  <Slider {...portfolioMobileSettings}>
                    {
                      portfolio.map(item => {
                        return (
                          <div key={item?.sys?.id}>
                            <img src={item?.fields?.images[0]?.fields?.file?.url}/>
                          </div>
                        )
                      })
                    }
                  </Slider>
                </div>
            }

            <Link to="/portfolio" className={`button button--blue-outlined button--uppercase ${styles.button}`}>Mais projetos <span>→</span></Link>
          </div>
        </div>
      </div>
    </section>
  )
}
