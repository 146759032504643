import React from 'react'
import { Link } from 'react-router-dom'
import { Logo, LinkedinIcon, InstagramIcon } from '../../assets'
import styles from './style.module.scss'

export const Footer = () => {
  const date = React.useRef()

  React.useEffect(() => {
    const d = new Date()
    date.current.innerText = d.getFullYear()
  })

	return (
		<footer className={styles.footer}>
      <div className="container">
        <div className={`flex ${styles.row}`}>
          <div className={`${styles.column} ${styles.logo}`}>
            <Logo />
            <p className={styles['text__helper']}>O futuro da gestão da sua expansão.</p>
          </div>
          <div className={styles.columns}>
            <div className={styles.column}>
              <p className={styles['column__title']}>Inicial</p>

              <ul className={styles.list}>
                <li><a href="/#services">Serviços</a></li>
                <li><Link to="/projetos">Projetos</Link></li>
                <li><a href={`https://wa.me/+551123917842/?text=${encodeURI('Olá! Gostaria de entender um pouco mais sobre a HUP e seus serviços.')}`}>Contato</a></li>
              </ul>
            </div>
            <div className={styles.column}>
              <p className={styles['column__title']}>Quem faz</p>

              <ul className={styles.list}>
                <li><Link to="/quem-faz">História</Link></li>
              </ul>
            </div>
            <div className={styles.column}>
              <p className={styles['column__title']}>Portfólio</p>

              <ul className={styles.list}>
                <li><Link to="/portfolio">Destaques</Link></li>
              </ul>
            </div>
          </div>
          <div className={styles.column}>
            <ul className={styles.social}>
              <li><a href="https://www.linkedin.com/company/hupxp/" target="_blank"><LinkedinIcon/></a></li>
              <li><a href="https://www.instagram.com/hupgeneration/" target="_blank"><InstagramIcon/></a></li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.disclaimer}>
        <p><span ref={date}></span> | <strong>HUP XP</strong> &reg; Todos os direitos reservados.</p>
      </div>
		</footer>
	)
}
