import React from 'react'
import Slider from 'react-slick'
import { client, getContentfulTags } from '../../services/api'
import { ArrowBlueRight, Burger, Grid, Cross, Loading } from '../../assets'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import {
  Page,
  Head
} from '../../components'

import "slick-carousel/slick/slick.css"
import styles from './style.module.scss'

export default function Portfolio() {
  const [ listType, setListType ] = React.useState("list")
  const [ listSelected, setListSelected ] = React.useState({})
  const [ projects, setProjects ] = React.useState([])
  const [ categories, setCategories ] = React.useState([])
  const [ articleSelected, setArticleSelected ] = React.useState({})
  const [ showModal, setShowModal ] = React.useState(false)
  const [ requesting, setRequesting ] = React.useState(true)

  const getProjects = async ({id, project, name}) => {
    const projects = await client.getEntries({
      content_type: "portfolio",
      'metadata.tags.sys.id[in]': project ? project?.sys?.id : id
    })

    setListSelected({name: name ?? project.name})
    setProjects(projects.items)
    setRequesting(false)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)

    // Calling categories from contentful
    const listTags = async () => {
      const tags = await getContentfulTags()
      getProjects({project: tags.items.reverse()[0]})
      setCategories(tags.items)
    }
    listTags()
  }, [])

  return (
    <Page>
      <Head title="Portfolio - HUPXP">
        <meta name="title" content="HUPXP - Nosso portfólio"/>
        <meta name="description" content="Nosso portfolio contempla as reformas junto a nossos parceiros. Atuando tanto em Flipping Houses até Full Service Renovations, ideal para aumentar o potencial de ganho do seu imóvel, de forma completamente diferenciada do mercado"/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://hupxp.com/portfolio"/>
        <meta property="og:title" content="HUPXP - Nosso portfólio"/>
        <meta property="og:description" content="Nosso portfolio contempla as reformas junto a nossos parceiros. Atuando tanto em Flipping Houses até Full Service Renovations, ideal para aumentar o potencial de ganho do seu imóvel, de forma completamente diferenciada do mercado"/>
        <meta property="og:image" content="/logohup.jpg"/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://hupxp.com/portfolio"/>
        <meta property="twitter:title" content="HUPXP - Nosso portfólio"/>
        <meta property="twitter:description" content="Nosso portfolio contempla as reformas junto a nossos parceiros. Atuando tanto em Flipping Houses até Full Service Renovations, ideal para aumentar o potencial de ganho do seu imóvel, de forma completamente diferenciada do mercado"/>
        <meta property="twitter:image" content="/logohup.jpg"/>
      </Head>

      <div className={styles.header}>
        <div className="container">
          <div className="flex">
            <div className={styles.column}>
              <h1>Portfólio</h1>
              <p>Nosso portfolio contempla as reformas junto a nossos parceiros. Atuando tanto em Flipping Houses até Full Service Renovations, ideal para aumentar o potencial de ganho do seu imóvel, de forma completamente diferenciada do mercado.</p>
            </div>
            <div className={styles.buttons}>
              <button
                className={listType === "list" ? styles.active : ""}
                type="button"
                onClick={() => setListType("list")}
              >
                <Burger />
              </button>
              <button
                className={listType === "images" ? styles.active : ""}
                type="button"
                onClick={() => setListType("images")}
              >
                <Grid />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.body}>
        {
          projects && !requesting ?
            <div className="container">
              <div className="flex">
                <div className={styles.sidebar}>
                  {
                    categories &&
                      <ul className={styles.list}>
                      {
                        categories.map(category =>
                          <li
                            key={category.sys.id}
                            data-id={category.sys.id}
                            onClick={() => getProjects({id: category.sys.id, name: category.name})}
                            className={listSelected.name === category.name ? styles.active : ""}
                          >
                            <p className={styles['list__title']}>{category.name} <span>→</span></p>
                          </li>
                        )
                      }
                      </ul>
                  }
                </div>
                <div
                  className={`
                    ${styles.projects}
                    ${listType !== "list" ? styles['project__images'] : ""}
                  `}
                >
                  {
                    projects.length !== 0 ?
                      projects.map(project =>
                        <article
                          key={project.sys.id}
                          className={styles.project}
                          style={{backgroundImage: `url(${listType !== "list" ? project.fields?.images[0]?.fields?.file?.url : ""}`}}
                          onClick={() => {
                            setArticleSelected(project)
                            setShowModal(true)
                          }}
                        >
                          <div>
                            <p className={styles['project__category']}>{listSelected.name}</p>
                            <h1 className={styles['project__title']}>{project.fields.title}</h1>

                            <ArrowBlueRight className={styles.arrow} />
                          </div>
                        </article>
                      )
                    : <div className={styles.alert}>
                        <h2>404</h2>
                        <span>Itens não encontrados.</span>
                        <p>Talvez não existam cadastros para essa categoria ou ocorreu um erro ao listar os devidos itens. <br/> Tente novamente mais tarde.</p>
                      </div>
                  }
                </div>
              </div>
            </div> :
            <Loading />
        }
      </div>

      <Modal
        project={articleSelected}
        listSelected={listSelected}
        show={showModal}
        setShowModal={setShowModal}
        setArticleSelected={setArticleSelected}
      />
    </Page>
  )
}

const Modal = ({project, listSelected, show, setShowModal, setArticleSelected}) => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    centerPadding: "10%"
  }

  return (<>
    <div
      className={`
        ${styles.modal}
        ${show ? styles.active : undefined}
      `}
    >
      <div className={styles['modal__head']}>
        <button
          type="button"
          onClick={()=> {
            setShowModal(false)
            setTimeout(() => setArticleSelected({}), 300)
          }}
        >
          <Cross />
        </button>

        <p className={styles.category}>{listSelected?.name}</p>
        <h1 className={styles.title}>{project?.fields?.title}</h1>

        <div className={styles.description}>
          {documentToReactComponents(project?.fields?.description)}
        </div>
      </div>
      <div className={styles['modal__body']}>
        {
          project?.fields?.images &&
          <Slider {...sliderSettings}>
            {
              project.fields.images.map(image => {
                if ( !image || !image?.fields || !image?.fields?.file ) return undefined

                return (
                  <img
                    key={image.sys.id}
                    className={styles.image}
                    src={image.fields.file.url}
                    alt={image.fields.description}
                  />
                )
                }
              )
            }
          </Slider>
        }
      </div>
    </div>

    <div
      className={styles['modal__backdrop']}
      onClick={()=> {
        setShowModal(false)
        setTimeout(() => setArticleSelected({}), 300)
      }}
    ></div>
  </>)
}
