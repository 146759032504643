export function Arch({className}) {
  return (
    <svg className={className} width="368" height="249" viewBox="0 0 368 249" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dddddd)">
      <path d="M80.5 116.984C80.5 59.8316 126.832 13.5 183.984 13.5C241.154 13.5 287.5 59.8455 287.5 117.016V248.5H80.5V116.984Z" stroke="white"/>
      </g>
      <defs>
      <filter id="filter0_dddddd" x="0" y="0" width="368" height="396" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1.24074"/>
      <feGaussianBlur stdDeviation="1.57407"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0216667 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="5.45926"/>
      <feGaussianBlur stdDeviation="3.25926"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0353333 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="13.4"/>
      <feGaussianBlur stdDeviation="6.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.045 0"/>
      <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="25.8074"/>
      <feGaussianBlur stdDeviation="12.7407"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0546667 0"/>
      <feBlend mode="normal" in2="effect3_dropShadow" result="effect4_dropShadow"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="43.4259"/>
      <feGaussianBlur stdDeviation="23.4259"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0683333 0"/>
      <feBlend mode="normal" in2="effect4_dropShadow" result="effect5_dropShadow"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="67"/>
      <feGaussianBlur stdDeviation="40"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"/>
      <feBlend mode="normal" in2="effect5_dropShadow" result="effect6_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect6_dropShadow" result="shape"/>
      </filter>
      </defs>
    </svg>

  )
}
