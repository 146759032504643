import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Logo, ChevronDown, Burger } from '../../assets'
import styles from './style.module.scss'

export const Navbar = () => {
  const [ selectedLanguage, setSelectedLanguage ] = React.useState('pt')
  const [ langStatus, setLangStatus ] = React.useState(false)
  const [ mobileMenuActive, setMobileMenuActive ] = React.useState(false)

  const menu = React.createRef()
  const bar = React.createRef()

  React.useEffect(() => {
    const language = navigator.language || navigator.userLanguage;

  }, [])

  const follower = (e) => {
    let parentRect = menu.current.getBoundingClientRect()
    let rect = e.target.getBoundingClientRect()

    bar.current.style.opacity = 1
    bar.current.style.left = ((rect.left + window.scrollX) - (parentRect.left + window.scrollX) + e.target.offsetWidth / 2 - 50) + "px"
    bar.current.style.width = 100 + "px"
  }

	return (
		<nav className={styles.navbar}>
      <div className="container">
        <div className={styles.burger} onClick={() => setMobileMenuActive(!mobileMenuActive)}>
          <Burger />
        </div>

        <div className="flex">
          <div className={styles.logo}>
            <Link to="/">
              <Logo />
            </Link>
          </div>

          <ul
            className={`
              ${styles.menu}
              ${mobileMenuActive ? styles['menu__active'] : undefined}
            `}
            ref={menu}
          >
            <span className={styles.bar} ref={bar}></span>
            <li><Link to="/" onMouseOver={follower}>INICIAL</Link></li>
            <li><Link to="/quem-faz" onMouseOver={follower}>QUEM FAZ</Link></li>
            <li><Link to="/portfolio" onMouseOver={follower}>PORTFÓLIO</Link></li>
          </ul>

          <div
            className={`${styles.lang} ${langStatus && styles['lang--active']}`}
            onClick={() => setLangStatus(!langStatus)}
            onMouseLeave={() => setLangStatus(false)}
          >
            <span>PT <ChevronDown /></span>

            <ul>
              <li>PT</li>
              <li className={styles.disabled}>EN</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className={`
          ${styles['mobile__backdrop']}
          ${mobileMenuActive ? styles['mobile__backdrop--active'] : undefined}
        `}
        onClick={() => setMobileMenuActive(false)}
      ></div>
		</nav>
	)
}
