import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Navbar, Footer, Location } from '..'

export const Page = ({children, head}) => {
  return (
    <div>
			<div>
				<Navbar />

				<main>
          {children}
				</main>

        <Location />
				<Footer />
			</div>
    </div>
  )
}

export const Head = ({title, children}) => {
  return (
    <Helmet>
      <title>{title ?? "HUP Experience"}</title>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="author" content="Lucas Henrique <eu@lhenrique.com.br>" />
      <link rel="icon" href="/favicon.ico?v1=true" />

      {children}

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-210316954-1"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-210316954-1');`,
        }}
      ></script>
    </Helmet>
  )
}
