import { ContentfulClient } from 'react-contentful';

const config = {
  url: process.env.REACT_APP_CONTENTFUL_API_URL || "https://api.contentful.com",
  token: process.env.REACT_APP_CONTENTFUL_API_TOKEN || "",
  space: process.env.REACT_APP_CONTENTFUL_SPACE || "",
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "homolog"
}

export const client = ContentfulClient({
  space: config.space || '',
  environment: config.environment,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
});

export const clientPreview = ContentfulClient({
  space: config.space,
  environment: config.environment,
  accessToken: process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  host: "preview.contentful.com"
})

export async function getContentfulTags() {
  try {
    const { url, space, environment, token } = config
    const path = `${url}/spaces/${space}/environments/${environment}/tags?skip=0&access_token=${token}`
    const tags = await fetch(path, {method: "GET"})

    return tags.json()
  } catch (e) {
    console.error(e)
  }
}
