import React from 'react'

import {
  Page,
  Head,
  Customers
} from '../../components'

import {
  Header,
  Services,
  Portfolio,
  Team
} from './_components'

export default function Home() {
  return (
    <Page>
      <Head title="Home - HUPXP">
        <meta name="title" content="HUPXP - Bem-vindo ao futuro da construção"/>
        <meta name="description" content="Gerenciamos expansão no modelo end-to-end com visão 360º do seu negócio, com tecnologia e processos escaláveis mais atualizados do mercado, de acordo com as necessidades da sua empresa"/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://hupxp.com/"/>
        <meta property="og:title" content="HUPXP - Bem-vindo ao futuro da construção"/>
        <meta property="og:description" content="Gerenciamos expansão no modelo end-to-end com visão 360º do seu negócio, com tecnologia e processos escaláveis mais atualizados do mercado, de acordo com as necessidades da sua empresa"/>
        <meta property="og:image" content="/logohup.jpg"/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://hupxp.com/"/>
        <meta property="twitter:title" content="HUPXP - Bem-vindo ao futuro da construção"/>
        <meta property="twitter:description" content="Gerenciamos expansão no modelo end-to-end com visão 360º do seu negócio, com tecnologia e processos escaláveis mais atualizados do mercado, de acordo com as necessidades da sua empresa"/>
        <meta property="twitter:image" content="/logohup.jpg"/>
      </Head>

      <Header />
      <Services />
      <Portfolio />
      <Team />
      <Customers />
    </Page>
  )
}
