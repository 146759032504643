export function MoneyIcon() {
  return (
    <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10.5591C0.269623 10.3353 0.504827 10.0243 0.814607 9.89997C8.9785 6.63135 17.1469 3.37442 25.3197 0.129168C25.966 -0.125795 26.212 -0.0199847 26.4715 0.623796L32.4306 15.5754C32.7034 16.2594 32.6008 16.5022 31.9207 16.7738C30.751 17.241 29.5833 17.712 28.4092 18.1678C28.2014 18.2487 28.1275 18.3488 28.1281 18.5776C28.137 21.429 28.1389 24.2803 28.1338 27.1316C28.1338 27.806 27.9426 27.9934 27.2599 27.9934C18.4612 27.9934 9.66201 27.9955 0.862412 27.9998C0.471045 27.9998 0.184211 27.8946 0.000638028 27.5459L0 10.5591ZM26.8348 26.702V11.4043H1.29139V26.702H26.8348ZM31.1054 15.7189C29.2047 10.946 27.3186 6.21258 25.4274 1.46454L3.91494 10.03C5.14514 10.0969 6.35877 10.0988 7.57302 10.0937C7.64314 10.0937 7.71644 10.03 7.78337 9.98857C7.94209 9.88914 8.0855 9.75528 8.25568 9.68644C10.2882 8.86929 12.3228 8.05744 14.3595 7.25091C16.6609 6.33687 18.9635 5.42559 21.2671 4.51708C21.645 4.36792 21.8496 4.4323 22.111 4.73571C22.9549 5.71413 24.0181 6.18134 25.3127 6.11888C25.7334 6.09848 25.9176 6.20238 26.0776 6.59311C26.5193 7.66969 26.9463 8.75264 27.3855 9.8305C27.4263 9.92993 27.4952 10.0753 27.5767 10.0931C28.0548 10.2021 28.1389 10.531 28.137 10.9542C28.1294 12.8091 28.1338 14.6646 28.137 16.5194C28.137 16.6304 28.1472 16.7406 28.1542 16.9019L31.1054 15.7189ZM10.8359 10.0326L10.8512 10.0797H26.1043C25.7856 9.27277 25.4669 8.50087 25.1743 7.72132C25.0889 7.49504 24.9831 7.39752 24.7237 7.38413C23.5126 7.32039 22.4641 6.85253 21.5864 6.02199C21.4149 5.86009 21.2894 5.86009 21.0899 5.93977C18.2899 7.06245 15.4879 8.18025 12.6837 9.29317C12.0676 9.53835 11.4516 9.78482 10.8359 10.0326Z" fill="#3A8AE3" stroke="#3A8AE3" strokeWidth="0.5"/>
      <path d="M14.0599 25.4382C11.4835 25.4382 8.90735 25.4382 6.33138 25.4382C5.86926 25.4382 5.71181 25.3145 5.55947 24.8798C5.13687 23.6687 4.32546 22.8509 3.10928 22.4417C2.75489 22.3225 2.56494 22.102 2.56494 21.7189C2.56494 19.9488 2.5592 18.1794 2.56877 16.4093C2.56877 15.9421 2.69625 15.8095 3.14243 15.6508C4.32355 15.2288 5.12923 14.4308 5.54737 13.2452C5.70927 12.7882 5.85523 12.6773 6.33711 12.6658C6.68704 12.6575 7.03827 12.6588 7.38693 12.6588C12.1887 12.6588 16.9905 12.6612 21.7923 12.6658C22.2755 12.6658 22.4221 12.7876 22.5821 13.2395C23.0002 14.4244 23.8065 15.2237 24.9857 15.6476C25.4427 15.8114 25.5651 15.9529 25.567 16.4348C25.5734 18.1839 25.5734 19.9329 25.567 21.6819C25.567 22.1281 25.4396 22.2913 25.0252 22.4341C23.8033 22.8586 22.9855 23.6796 22.5655 24.9053C22.4214 25.3292 22.2723 25.4369 21.8172 25.4369C19.2323 25.4377 16.6465 25.4382 14.0599 25.4382ZM14.081 13.9343H6.95668C6.78649 13.9343 6.65008 13.9247 6.55511 14.137C6.042 15.2843 5.19742 16.1199 4.05901 16.6503C3.89265 16.7274 3.82573 16.8141 3.82701 17.0027C3.8355 18.36 3.8355 19.7168 3.82701 21.0732C3.82701 21.2715 3.89075 21.3683 4.0705 21.4557C5.19807 21.9822 6.03307 22.8108 6.55064 23.9415C6.63669 24.1328 6.74633 24.1736 6.93755 24.1736C11.6969 24.1685 16.4562 24.1685 21.2155 24.1736C21.4169 24.1736 21.5042 24.1098 21.5883 23.9262C22.087 22.8231 22.9731 21.9413 24.0787 21.448C24.2514 21.369 24.3056 21.2765 24.305 21.093C24.2973 19.7468 24.2973 18.4006 24.305 17.0544C24.305 16.8383 24.2501 16.7248 24.0411 16.628C22.9569 16.1392 22.0885 15.2713 21.5992 14.1873C21.4997 13.9738 21.3806 13.9324 21.1702 13.9324C18.8084 13.9349 16.4453 13.9345 14.081 13.9311V13.9343Z" fill="#3A8AE3" stroke="#3A8AE3" strokeWidth="0.5"/>
      <path d="M17.8977 19.0552C17.8901 21.1752 16.1525 22.9038 14.0478 22.8847C13.2886 22.8826 12.547 22.6553 11.9169 22.2318C11.2868 21.8082 10.7964 21.2073 10.5078 20.5051C10.2191 19.8028 10.1452 19.0308 10.2953 18.2865C10.4455 17.5422 10.8129 16.8592 11.3512 16.3238C11.8895 15.7883 12.5745 15.4245 13.3195 15.2784C14.0645 15.1322 14.8362 15.2102 15.5369 15.5026C16.2376 15.7949 16.8359 16.2885 17.2561 16.9208C17.6763 17.5532 17.8996 18.2959 17.8977 19.0552ZM16.6165 19.0386C16.614 18.5354 16.4626 18.0442 16.1815 17.6268C15.9003 17.2094 15.5019 16.8846 15.0364 16.6933C14.571 16.5019 14.0594 16.4526 13.5659 16.5515C13.0725 16.6504 12.6194 16.893 12.2636 17.249C11.9079 17.6049 11.6654 18.0581 11.5668 18.5516C11.4681 19.0451 11.5177 19.5567 11.7093 20.0221C11.9009 20.4874 12.2259 20.8856 12.6434 21.1666C13.0609 21.4476 13.5522 21.5987 14.0554 21.601C14.3925 21.6028 14.7266 21.5378 15.0384 21.4097C15.3502 21.2816 15.6335 21.093 15.8719 20.8547C16.1103 20.6164 16.2991 20.3332 16.4274 20.0215C16.5556 19.7097 16.6208 19.3757 16.6191 19.0386H16.6165Z" fill="#3A8AE3" stroke="#3A8AE3" strokeWidth="0.5"/>
      <path d="M7.0357 19.9973C6.78399 19.9973 6.54242 19.8981 6.36342 19.7211C6.18442 19.5441 6.08244 19.3037 6.07959 19.052C6.07744 18.8625 6.13168 18.6766 6.23543 18.5179C6.33917 18.3593 6.48773 18.235 6.66224 18.161C6.83674 18.087 7.02931 18.0665 7.21549 18.1022C7.40166 18.1378 7.57304 18.228 7.70784 18.3613C7.84265 18.4946 7.93479 18.6649 7.97256 18.8506C8.01034 19.0364 7.99205 19.2292 7.92001 19.4045C7.84798 19.5799 7.72545 19.7298 7.56799 19.8354C7.41053 19.9409 7.22526 19.9973 7.0357 19.9973Z" fill="#3A8AE3" stroke="#3A8AE3" strokeWidth="0.5"/>
      <path d="M20.1516 19.0482C20.1492 18.7958 20.2467 18.5528 20.4228 18.372C20.5989 18.1912 20.8394 18.0874 21.0918 18.0832C21.349 18.0855 21.5951 18.1885 21.7772 18.3701C21.9594 18.5517 22.0632 18.7974 22.0664 19.0546C22.0604 19.3066 21.9561 19.5463 21.7758 19.7224C21.5955 19.8986 21.3534 19.9972 21.1013 19.9973C20.9766 19.9975 20.8531 19.973 20.7378 19.9254C20.6226 19.8778 20.5178 19.8079 20.4296 19.7197C20.3414 19.6316 20.2714 19.5269 20.2237 19.4117C20.176 19.2964 20.1515 19.1729 20.1516 19.0482Z" fill="#3A8AE3" stroke="#3A8AE3" strokeWidth="0.5"/>
    </svg>
  )
}
