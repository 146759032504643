import React from 'react'
import Slider from 'react-slick'

import "slick-carousel/slick/slick.css"
import "../../styles/customers.css"
import styles from './style.module.scss'

import cabezo from '../../assets/logo_cabezo.png'
import msx from '../../assets/logo_msx_go.png'
import construcan from '../../assets/logo_construcan.png'
import loft from '../../assets/logo_loft.png'
import nomah from '../../assets/logo_nomah.png'
import wework from '../../assets/wework_logo.png'
import uliving from '../../assets/uliving_logo.png'

const customersList = [
  { name: "Loft", logo: loft },
  { name: "Nomah", logo: nomah },
  { name: "ULiving", logo: uliving },
  { name: "WeWork", logo: wework },
  { name: "Construcan", logo: construcan },
  { name: "MSX", logo: msx },
  { name: "Cabezo", logo: cabezo }
]

export function Customers() {
  const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: false,
    infinite: true,
    centerPadding: "10%",
    autoplay: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <section className={styles.customers}>
      <div className="container customers">
        <Slider {...sliderSettings}>
          {
            customersList.map(customer =>
              <div key={customer.name} className={styles.customer} title={customer.name}>
                <img src={customer.logo} alt={customer.name} />
              </div>
            )
          }
        </Slider>
      </div>
    </section>
  )
}
