import React from 'react'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'

export default function NotFound() {
	return (
		<div className={styles.notfound}>

    </div>
	)
}
