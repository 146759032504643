export function Ellipse({className}) {
  return (
    <svg className={className} width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="55" cy="55" r="54.5" stroke="#1EAFCD"/>
    </svg>
  )
}

export function EllipseWhite({className}) {
  return (
    <svg className={className} width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="73.5" cy="73.5" r="73" stroke="#E1E1E1"/>
    </svg>
  )
}
