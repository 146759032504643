import React from 'react'
import {
  WhatsappIcon,
  ChevronDown,
  Ellipse,
  Smile,
  MoneyIcon,
  WallIcon,
  BuildingIcon,
  EllipseWhite
} from '../../../../assets'
import image from '../../../../assets/desktop_kitchen.png'

import styles from './style.module.scss'

export function Header() {
  const [ dropdown, setDropdown ] = React.useState(false)
  const [ dropdownValue, setDropdownValue ] = React.useState("Que tipo de cliente você é?")
  const [ whatsapp, setWhatsapp ] = React.useState(`${encodeURI('Olá! Gostaria de entender um pouco mais sobre a HUP e seus serviços.')}`)

  const changeDropdown = (value) => {
    setDropdownValue(value)
    setWhatsapp(encodeURI(`Olá! Eu ${value.toLowerCase()} e gostaria de entender um pouco mais sobre a HUP e seus serviços.`));
  }

  return (
    <header className={styles.header}>
      <div className="container">
        <div className={`flex ${styles.wrapper}`}>
          <div className={styles.content}>
            <p className={styles.category}>HUP Experience</p>
            <h1 className={styles.title}>Bem-vindo ao futuro da construção.</h1>

            <p className={styles.text}>Gerenciamos expansão no modelo end-to-end com visão 360º do seu negócio, com tecnologia e processos escaláveis mais atualizados do mercado, de acordo com as necessidades da sua empresa.</p>

            <div className={styles.contact}>
              <div
                className={`${styles.dropdown} ${dropdown && styles['dropdown--active']}`}
                onClick={() => setDropdown(!dropdown)}
                onMouseLeave={() => setDropdown(false)}
              >
                <span className={styles.label}>
                  <span>{dropdownValue}</span>
                  <ChevronDown className={styles.chevron}/>
                </span>

                <ul>
                  <li onClick={() => changeDropdown('Sou um investidor')}><MoneyIcon /> Sou um investidor</li>
                  <li onClick={() => changeDropdown('Sou uma empresa')}><WallIcon /> Sou uma empresa</li>
                  <li onClick={() => changeDropdown('Sou um incorporador')}><BuildingIcon /> Sou um incorporador</li>
                  <li onClick={() => changeDropdown('Tenho um apartamento')}><BuildingIcon /> Tenho um apartamento</li>
                </ul>
              </div>

              <a href={`https://wa.me/+551123917842/?text=${whatsapp}`} target="_blank" className="button button--blue">
                <WhatsappIcon /> Falar agora
              </a>
            </div>
          </div>
          <div className={styles.image}>
            <Ellipse className={`${styles.ellipse} animation-jump--fast`} />
            <Smile className={`${styles.smile} animation-jump--slow`} />
            <div
              className={styles['image__block']}
              style={{backgroundImage: `url(${image})`}}
            ></div>
          </div>
        </div>
      </div>

      <EllipseWhite className={`${styles['ellipse--white']} animation-jump--slow-alternate`}/>
    </header>
  )
}
