import React from 'react'
import Slider from 'react-slick'
import { ShieldLarge, EllipseWhite, SmileSecondary } from '../../assets'
import { Page, Head, Customers } from '../../components'
import team from '../../assets/team.webp'
import bgteam from '../../assets/bgteam.jpg'
import "slick-carousel/slick/slick.css"
import styles from './style.module.scss'

import rafa from "../../assets/tm2.webp"
import marina from "../../assets/tm3.webp"
import leo from "../../assets/tm4.webp"
import luiz from "../../assets/tm5.webp"
import ivan from "../../assets/tm6.webp"
import franca from "../../assets/tm7.webp"

const teammates = [
  { name: "Ivan Russi", role: "Founder & CEO", img: ivan },
  { name: "Luiz Bouch", role: "Expansion Manager", img: luiz },
  { name: "Marina Zafiro", role: "Quality & Process Control Manager", img: marina },
  { name: "Leonardo Pires", role: "Expansion Administration Lead", img: leo },
  { name: "Rafael Gavião", role: "Expansion Lead", img: rafa },
  { name: "Gabriel França", role: "Expansion Lead", img: franca }
]

const testimonials = [
  {name: "Marcus Grigoletto", role: "Co-Founder na Loft", text: "Desde a sua concepção, a HUP chegou para revolucionar a forma de geração de valor para parceiros de grande porte, com muito foco em processos e tecnologia entregam resultados claros além do esperado! A HUP chegou para transformar o mercado de gerenciamento, com muitos  relacionamentos, processos e tecnologia consegue entregar uma experiência incrível para seus clientes!"},
  {name: "Bruna Parkinson", role: "Territory Director na WeWork", text: "A Hup é uma empresa diferente. É atual, traz tecnologia e fez os orçamentos para nós com bastante agilidade, pensando sempre na nossa expansão."},
  {name: "Marcus Petroski", role: "Head de Engenharia na Nomah", text: "A HUP demonstra organização, agilidade e comprometimento acima da média do mercado"},
  {name: "Gabrielle Paixão", role: "Arquiteta na NOMAH", text: "HUP tem se mostrado uma grande parceira para nós da Nomah - minha área de Orçamentos que o diga! A vontade de fazer acontecer é uma das características mais marcantes que contagia até nós, parceiros. O cuidado em sempre atender com o melhor custo, no prazo e dedicação é super notório! Assim como a Nomah, estou ansiosa para ver HUP alçando vôos cada vez mais altos."},
  {name: "Thyene Schmidt", role: "Home Planning na Loft", text: "A HUP conseguiu, em pouco tempo, entender nossas necessidades e nos trazer soluções eficientes, baseadas em inovação e tecnologia, para nos ajudar na otimização de gerenciamento de obras. Se transformam e se adaptam para atender cada nova demanda, sempre com muita transparência e parceria."},
  {name: "Emanuelle Fontanesi", role: "Engenheira MEP", text: "Parabéns pelo lindo site! A HUP traz novidades e tecnologia misturado com um time competente e de extrema dedicação, formado por profissionais unidos e com o pensamento de equipe tão forte que nos faz querer fazer parte do time! Além de pessoas muito queridas! Que venham novos clientes, novos trabalhos, desafios e com certeza muitas realizações. Voa equipe!!!"},
]

export default function Team() {
  const cardsSettings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    infinite: false,
    centerPadding: "10%",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const categoriesSettings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    infinite: true,
    centerPadding: "10%",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const servicesSettings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: false,
    centerMode: true,
    centerPadding: "10%",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Page>
      <Head title="Quem somos - HUPXP">
        <meta name="title" content="HUPXP - Quem somos"/>
        <meta name="description" content="Sabemos que no mercado da construção civil é difícil acompanhar todas as fases que uma obra exige. Por isso, nos posicionamos como uma Construtech voltado a atender às necessidades dentro e fora do canteiro de obras"/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://hupxp.com/portfolio"/>
        <meta property="og:title" content="HUPXP - Quem somos"/>
        <meta property="og:description" content="Sabemos que no mercado da construção civil é difícil acompanhar todas as fases que uma obra exige. Por isso, nos posicionamos como uma Construtech voltado a atender às necessidades dentro e fora do canteiro de obras"/>
        <meta property="og:image" content="/logohup.jpg"/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://hupxp.com/portfolio"/>
        <meta property="twitter:title" content="HUPXP - Quem somos"/>
        <meta property="twitter:description" content="Sabemos que no mercado da construção civil é difícil acompanhar todas as fases que uma obra exige. Por isso, nos posicionamos como uma Construtech voltado a atender às necessidades dentro e fora do canteiro de obras"/>
        <meta property="twitter:image" content="/logohup.jpg"/>
      </Head>

      <div className={styles.team}>
        <div className={styles.banner} style={{backgroundImage: `url(${team})`}}></div>
        <img src={team} className={styles.bannerMobile}/>

        <header>
          <div className="container">
            <SmileSecondary className={`${styles.smile} animation-jump--fast`} />

            <div className={styles.titles}>
              <h1 className={styles['page__title']}>Olá, somos a geração HUP!</h1>
            </div>

            <div className={styles.content}>
              <p>Sabemos que no mercado da construção civil é difícil acompanhar todas as fases que uma obra exige. Por isso, nos posicionamos como uma <i>Construtech</i> voltado a atender às necessidades dentro e fora do canteiro de obras.</p>
              <p>Imaginem ter tudo isso a disposição sempre que quiser e onde você estiver? É esse o nosso <i>Core Business</i> e vamos te ajudar na sua expansão!</p>
              <p>Somos visionários e queremos romper com a forma atual. Para isto, empregamos uma gestão antes não vista, aliando tecnologia à produção, integrando planejamento estratégico, além de sistemas de avaliação de qualidade e desenvolvimento de processos.</p>
              <p>Nossa proposta é modernizar o setor, trazendo visibilidade, escalabilidade e gerenciamento.</p>
            </div>

            <div className="cards">
              <div className={styles.cards}>
                <Slider {...cardsSettings}>
                  <div className={styles.card}>
                    <p><strong>Missão: </strong>Dar visibilidade e criar escalabilidade para processos e gerenciamento de obras.</p>
                  </div>
                  <div className={styles.card}>
                    <p><strong>Visão: </strong>Mudar a forma dos processos, metodologias e tecnologias em Construção Civil a âmbito nacional.</p>
                  </div>
                  <div className={styles.card}>
                    <p><strong>Valores: </strong>Inovar o mercado de Engenharia Civil e Gerenciamento de Obras para proporcionar um alto nível de modernidade.</p>
                  </div>
                </Slider>
              </div>
            </div>

            <EllipseWhite className={`${styles.ellipse} animation-jump--slow`}/>
          </div>
        </header>

        <section className={`${styles.cats} animation-bg`} style={{backgroundImage: `url(${bgteam})`}}>
          <div className="container">
            <div className="categories">
              <Slider {...categoriesSettings}>
                {
                  teammates && teammates.map((player, index) => {
                    return (
                      <div className={styles.cat} key={player.name}>
                        <div className="flex">
                          <div>
                            <img src={player.img}/>

                            <h1>{player.name}</h1>
                            <p>{player.role}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          </div>
        </section>

        <section className={styles.services}>
          <div className="container">
            <p className={styles['services__category']}>Nossos serviços</p>
            <h1 className={styles['services__title']}>Testemunhos</h1>

            <div className="services">
              <div className={styles['services__cards']}>
                <Slider {...servicesSettings}>
                  {
                    testimonials.map(testimonial => {
                      return (
                        <div key={Math.random()} className={styles.service}>
                          <h1 className={styles.title}>{testimonial.name}</h1>
                          <p className={styles.role}>{testimonial.role}</p>
                          <p>{testimonial.text}</p>
                        </div>
                      )
                    })
                  }
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div>
        <Customers />
      </div>
    </Page>
  )
}
