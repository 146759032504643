import React from 'react'
import { Link } from 'react-router-dom'
import { Bullseye, Pork, Hands, Shield, SmileLarge } from '../../../../assets/vectors'
import { useWindowSize } from '../../../../hooks'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import styles from './style.module.scss'

export function Services() {
  const { width } = useWindowSize()

  const articlesSettings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: false,
    infinite: true,
    centerPadding: "10%",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  React.useEffect(() => {

  }, [])

  return (
    <section id="services" className={styles.services}>
      <div className="container">
        <p className={styles.category}>Serviços</p>
        <h1 className={styles.title}>O poder da nossa escala</h1>

        <div className={styles['services__list']}>
          {
            width >= 1200 ? <>
            <article className={styles.service}>
              <div className={styles.icon}>
                <Bullseye />
              </div>


              <h1>Direto ao Ponto</h1>
              <p>Entendemos sua expansão e seus processos atuais.</p>
            </article>
            <article className={styles.service}>
              <div className={styles.icon}>
                <Pork />
              </div>

              <h1>Expertise</h1>
              <p>Selecionamos o melhor time de especialistas para te atender.</p>
            </article>
            <article className={styles.service}>
              <div className={styles.icon}>
                <Shield />
              </div>

              <h1>Segurança</h1>
              <p>Selecionamos os melhores players do mercado para o seu negócio.</p>
            </article>
            <article className={styles.service}>
              <div className={styles.icon}>
                <Hands />
              </div>

              <h1>Transparência</h1>
              <p>Adequamos a tecnologia apropriada para sua expansão, dando toda visibilidade e transparência que você precisa.</p>
            </article></>
            : <Slider {...articlesSettings}>
                <article className={styles.service}>
                  <div className={styles.icon}>
                    <Bullseye />
                  </div>


                  <h1>Direto ao Ponto</h1>
                  <p>Entendemos sua expansão e seus processos atuais.</p>
                </article>
                <article className={styles.service}>
                  <div className={styles.icon}>
                    <Pork />
                  </div>

                  <h1>Expertise</h1>
                  <p>Selecionamos o melhor time de especialistas para te atender.</p>
                </article>
                <article className={styles.service}>
                  <div className={styles.icon}>
                    <Shield />
                  </div>

                  <h1>Segurança</h1>
                  <p>Selecionamos os melhores players do mercado para o seu negócio.</p>
                </article>
                <article className={styles.service}>
                  <div className={styles.icon}>
                    <Hands />
                  </div>

                  <h1>Transparência</h1>
                  <p>Adequamos a tecnologia apropriada para sua expansão, dando toda visibilidade e transparência que você precisa.</p>
                </article>
              </Slider>
          }
        </div>

        <Link to="/quem-faz" className={`button button--color-white ${styles.btncustom}`}>Conheça quem faz <span>→</span></Link>
      </div>

      <SmileLarge className={`${styles.smile} animation-jump--fast`} />
    </section>
  )
}
