export function BuildingIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0279 6.82616C14.9658 6.82616 15.9042 6.82616 16.8421 6.82616C17.3945 6.82616 17.7718 7.16577 17.7653 7.64648C17.7593 8.11679 17.3824 8.46132 16.8542 8.46241C14.9416 8.46606 13.0293 8.46606 11.1174 8.46241C10.6471 8.46241 10.3021 8.18077 10.2331 7.76788C10.2027 7.57718 10.2415 7.38188 10.3425 7.21729C10.4435 7.05269 10.6 6.92967 10.7838 6.87046C10.9061 6.83666 11.0328 6.82136 11.1596 6.82507C12.116 6.82452 13.0703 6.82616 14.0279 6.82616Z" fill="#3A8AE3"/>
      <path d="M14.0044 4.98916C13.02 4.98916 12.0357 4.99791 11.054 4.98588C10.4103 4.97768 10.0303 4.34221 10.3239 3.79314C10.3918 3.65746 10.4972 3.54411 10.6276 3.46656C10.758 3.389 10.9079 3.35051 11.0595 3.35564C13.0173 3.35072 14.9746 3.34744 16.9324 3.35564C17.0424 3.35401 17.1516 3.37452 17.2535 3.41594C17.3554 3.45737 17.4479 3.51886 17.5256 3.59677C17.6032 3.67468 17.6644 3.76741 17.7055 3.86945C17.7466 3.97148 17.7667 4.08074 17.7647 4.19072C17.7538 4.64791 17.3983 4.98533 16.9001 4.98861C15.9349 4.99463 14.9696 4.98861 14.0017 4.98861L14.0044 4.98916Z" fill="#3A8AE3"/>
      <path d="M13.9841 11.9263C13.0362 11.9263 12.0883 11.9263 11.1404 11.9263C10.5858 11.9263 10.2183 11.5905 10.2238 11.0999C10.2293 10.6242 10.5995 10.2878 11.1316 10.2867C13.0348 10.2845 14.9381 10.2845 16.8415 10.2867C17.3846 10.2867 17.7712 10.6395 17.763 11.118C17.7548 11.5965 17.3852 11.9246 16.8503 11.9257C15.8966 11.9279 14.9401 11.9263 13.9841 11.9263Z" fill="#3A8AE3"/>
      <path d="M13.977 18.8672C13.0025 18.8672 12.028 18.8721 11.0535 18.8645C10.8527 18.8678 10.6578 18.7972 10.5059 18.666C10.3539 18.5348 10.2556 18.3523 10.2296 18.1532C10.2037 17.9542 10.2519 17.7526 10.3651 17.5868C10.4783 17.421 10.6486 17.3027 10.8434 17.2545C10.9229 17.2353 11.0045 17.2265 11.0863 17.2282C13.0262 17.2282 14.966 17.2282 16.9056 17.2282C17.2933 17.2282 17.5919 17.4355 17.7122 17.7675C17.8216 18.0754 17.7598 18.4456 17.4934 18.6326C17.303 18.7657 17.0795 18.8435 16.8476 18.8574C15.89 18.8787 14.933 18.8672 13.977 18.8672Z" fill="#3A8AE3"/>
      <path d="M13.9962 13.7572C14.9076 13.7572 15.8191 13.7572 16.7306 13.7572C16.8488 13.7543 16.967 13.7601 17.0844 13.7747C17.2707 13.8043 17.4409 13.8979 17.5656 14.0395C17.6902 14.1812 17.7615 14.3619 17.7672 14.5504C17.7728 14.739 17.7124 14.9237 17.5965 15.0725C17.4805 15.2213 17.3162 15.325 17.132 15.3656C16.9977 15.3893 16.8614 15.3994 16.7251 15.3957C14.9022 15.3979 13.0811 15.3979 11.2618 15.3957C11.2345 15.3957 11.2071 15.3957 11.1798 15.3957C10.6001 15.3891 10.2271 15.0675 10.2288 14.571C10.2304 14.0744 10.5995 13.7578 11.1858 13.7561C12.1204 13.7556 13.0583 13.7578 13.9962 13.7572Z" fill="#3A8AE3"/>
      <path d="M27.2891 28H0.710938C0.372969 27.8906 0.121406 27.6872 0 27.3437V26.9609C0.190313 26.4911 0.553439 26.2937 1.06805 26.3369V7.23292C1.06805 6.49792 1.3568 6.21136 2.09563 6.21081C3.59771 6.21081 5.10034 6.21081 6.60352 6.21081H6.94531V5.88269C6.94531 4.28909 6.95954 2.6955 6.93821 1.1019C6.93055 0.546277 7.11375 0.172215 7.65625 0.00213623H20.3438C20.8797 0.181511 21.0547 0.562684 21.052 1.11284C21.0399 5.78535 21.0376 10.4577 21.0448 15.1298V15.5093H25.9038C26.6257 15.5093 26.9144 15.797 26.9144 16.5156C26.9144 19.6758 26.9144 22.8362 26.9144 25.9968V26.3468C27.446 26.2921 27.8053 26.4917 27.9995 26.9626V27.3454C27.8764 27.685 27.6292 27.8933 27.2891 28ZM8.60235 1.67065V26.3271H10.3256V26.0154C10.3256 24.6394 10.3256 23.2635 10.3256 21.8875C10.3256 21.2346 10.6263 20.9283 11.2716 20.9278C13.0847 20.9278 14.898 20.9278 16.7114 20.9278C17.3573 20.9278 17.6635 21.2368 17.6635 21.8832C17.6635 23.2686 17.6635 24.654 17.6635 26.0394V26.3254H19.3884V1.67065H8.60235ZM25.2585 20.96H21.0618V26.3304H25.2585V20.96ZM16.0092 26.3326V22.5859H11.988V26.3336L16.0092 26.3326ZM2.72508 16.258H6.92671V12.7525H2.72508V16.258ZM6.93109 17.9446H2.72781V21.4375H6.93109V17.9446ZM6.93109 23.1098H2.73438C2.72141 23.1535 2.71263 23.1984 2.70813 23.2438C2.70813 24.2085 2.70812 25.1737 2.7021 26.1384C2.7021 26.344 2.81148 26.3479 2.96078 26.3473C4.18942 26.3448 5.41844 26.3448 6.64782 26.3473C6.73641 26.3473 6.82554 26.3397 6.92836 26.3353L6.93109 23.1098ZM6.93766 7.87714H2.72399V11.0786H6.93766V7.87714ZM25.2656 17.168H21.064V19.28H25.2656V17.168Z" fill="#3A8AE3"/>
    </svg>
  )
}
