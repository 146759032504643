import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Route from './Route';

import Home from '../pages/Home'
import PortfolioPage from '../pages/Portfolio'
import Team from '../pages/Team'
import NotFound from '../pages/NotFound'

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/portfolio" exact component={PortfolioPage} />
        <Route path="/quem-faz" exact component={Team} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
